.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 55px;
  width: 100%;
  .lists_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .buttons_container {
    position: fixed;
    bottom: 10px;
    display: flex;
    gap: 15px;
    margin-top: auto;
    justify-content: center;
    z-index: 10;
  }
}
