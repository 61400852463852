@import "../../../index.scss";

.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-inline: 24px;
    min-width: 600px;
    @media screen and (max-width: 600px) {
      min-width: auto;
      width: 100%;
      overflow: scroll;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      gap: 20px;

      .header {
        display: flex;
        flex-direction: column;
        gap: 12px;

        h2 {
          font-size: 24px;
          font-weight: 900;
          color: #548ad3;
        }

        h3 {
          padding: 0;
          text-transform: capitalize;
          font-weight: 900;
          font-size: 36px;
          color: $textSecondaryColor;
          margin-bottom: 32px;
        }
      }

      > span {
        color: #548ad3;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 3px;
      }

      .buttons_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
      }
    }
    input {
      line-height: 16px;
    }

    p {
      font-family: "Akrobat";
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(120deg, #592fe6 0%, #6fc9e0 55%, #7bb0dc 89%, #7ca6dd 100%);
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .right {
    width: 50%;
    overflow: hidden;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
