@import "../../../index.scss";

.container {
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  gap: 42px;
  h4 {
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    color: #598dd5;
  }
  .left,
  .right {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 320px;
    width: 380px;
    box-sizing: border-box;
    height: 566px;
  }
  .left {
    padding: 50px 0;
    // .input_group {
    //   display: flex;
    //   justify-content: space-between;
    //   gap: 16px;
    // }

    h4 {
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;
      font-family: "Akrobat";
    }

    .payment_options {
      display: flex;
      flex-direction: column;
      gap: 11px;

      > div {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 56px;
        width: 100%;
        padding: 20px;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
        color: #575757;

        &.selected {
          position: relative;
          font-weight: 900;
          color: #665ae3;

          svg {
            color: #665ae3;
          }
        }

        &.selected::before {
          content: "";
          position: absolute;
          border-radius: 8px;
          inset: 0;
          border: 3px solid transparent;
          background: linear-gradient(90deg, #30aae5, #6431e6) border-box;
          mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
          mask-composite: exclude;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      margin-top: 5px;
      margin-inline: 12px;
      text-align: center;

      a {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
      }
    }
    .buttons_container {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      div {
        text-transform: uppercase;
      }
    }

    span {
      margin-top: 25px;
    }
  }
  .right {
    position: relative;
    background: linear-gradient(#ffffff, #eef0fe);
    border-radius: 32px;
    padding: 88px 42px 33px 42px;
    color: $textSecondaryColor;
    min-width: 380px;

    .plan_label {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
      max-width: 182px;
      width: 55%;
      height: 40px;
      padding-inline: 9px;
      padding-top: 2px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      background: linear-gradient(90deg, #6fc9e0 -29%, #665ae3 100%);
      font-family: Akrobat;
      font-size: 18px;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      border-radius: 32px;
      inset: 0;
      border: 8px solid transparent;
      background: linear-gradient(34deg, #665ae3 -2%, #6fc9e0 99%) border-box;
      mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
      mask-composite: exclude;
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 12px;
      h2 {
        font-size: 24px;
        font-weight: 900;
        color: #548ad3;
      }

      h3 {
        padding: 0;
        text-transform: capitalize;
        font-weight: 900;
        font-size: 36px;
        color: $textSecondaryColor;
        margin-bottom: 34px;
      }
    }
    label {
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 500;
      color: #548ad3;
      margin-bottom: 16px;
    }

    .select {
      margin-bottom: 17px;
      > div {
        height: 56px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-left: 21px;
        font-family: "Akrobat";
        font-size: 21px;
        font-weight: 900;
        padding-top: 11px;
      }

      > svg {
        font-size: 2rem; // Change the size of the icon
        width: 32px; // Optional: Explicit width
        height: 32px; // Optional: Explicit height
        color: #548ad3;
        margin-right: 6px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding-top: 8px;
      padding-bottom: 8px;

      & > div {
        display: flex;
        justify-content: space-between;
        & > span:first-of-type {
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
        }
        & > span:last-of-type {
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          text-align: right;
        }
        & > span.highlight {
          font-weight: 700;
          text-align: right;
          line-height: 16px;
          &:last-of-type {
            font-size: 16px;
            gap: 4px;
          }
          span {
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 500;
          }
        }

        .type {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    div:last-of-type {
      margin-top: auto;
    }

    .plan_bottom {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 12px;
    }
  }
}
