@import "../../../../index.scss";
.container {
  display: flex;
  flex-direction: column;
  //max-width: 1300px;
  //max-height: 80vh;

  .main {
    width: 100%;
    overflow: scroll;
    padding-left: 6px;
    //min-height: 358px;

    .nav {
      display: flex;
      align-items: center;
      padding-top: 10px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        gap: 14px;
        padding-bottom: 22px;
        padding-inline: 12px;
        color: #575757;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        cursor: pointer;
        transition: 0.35s ease;
        border-bottom: 1px solid #e6e6e6;
        &.active {
          border-bottom: 1px solid $mainColor;
          box-shadow: 0 1px $mainColor, inset 0px -1px $mainColor;
        }

        .search_icon {
          padding-bottom: -1px;
          width: 24px;
          height: 24px;
        }
      }

      span:first-of-type {
        padding-left: 0;
      }

      span:last-of-type {
        flex: 1;
        justify-content: end;
        padding-right: 0;
      }
    }
  }
}
