@import "../../index.scss";

.container {
  border-radius: $borderRadius;
  border-top: 1px solid transparent;
  background-color: #f8f8f8;

  > div:first-of-type {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 42px;
    margin: 14px 16px;

    svg {
      cursor: pointer;
      color: #939ea8;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #598dd5;
      }
    }
  }
  > .content {
    background-color: #ffffff;
    padding: 25px 42px 42px;
    border-radius: 0 0 $borderRadius $borderRadius;
  }
}
