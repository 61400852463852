.canvas_container {
  width: 100%;
  position: relative;
}
.views {
  position: absolute;
  right: 365px;
  top: 200px;
  z-index: 9;
  color: #6274854d;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  gap: 10px;
  > div {
    width: 84px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.views_preview {
  position: absolute;
  right: 365px;
  top: 80px;
  z-index: 9;
  color: #6274854d;
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-items: center;
  gap: 10px;
  // border-bottom: 1px solid red;
  > div {
    width: 84px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
