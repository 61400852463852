@import "../../../index.scss";

.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    min-width: 900px;
    overflow-x: scroll;
    @media screen and (max-width: 900px) {
      width: 100%;
      min-width: auto;
      overflow: scroll;
    }
    .wrapper {
      width: 100%;
      max-width: 900px;
      overflow: scroll;
      padding-inline: 5%;
      .header {
        align-self: start;
        display: flex;
        flex-direction: column;
        gap: 12px;
        h2 {
          font-size: 24px;
          font-weight: 900;
          color: #548ad3;
        }

        h3 {
          padding: 0;
          text-transform: capitalize;
          font-weight: 900;
          font-size: 36px;
          color: $textSecondaryColor;
          margin-bottom: 32px;
        }
      }

      > .payment_success_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > .success_box {
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          width: 100%;
          height: 186px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 40px;
          margin-bottom: 40px;
          padding-inline: 40px;
          font-family: "Roboto";
          font-size: 16px;
          font-weight: 400;
          color: #575757;
          text-align: center;
        }
      }
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .right {
    width: 30%;
    overflow: hidden;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
