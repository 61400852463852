@import "../../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 1280px;
  //max-height: 80vh;
  //height: 640px;
  max-height: calc(100vh - 90px);
  @media screen and (max-width: 1440px) {
    width: 100%;
    max-height: calc(100vh - 20px);
  }
  padding: 17px;
  gap: 17px;

  .img_container {
    width: 25px;
    height: 25px;
  }

  > .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: static;
    top: 0;
    text-align: center;
    padding: 20px 8px;
    background-image: url("./assets/header_background.jpg");
    height: 100px;
    width: 100%;
    border-radius: $borderRadius;

    h2 {
      font-size: 36px;
      font-weight: 600;
      line-height: 120%;
      color: white;
      margin: auto;
      text-transform: uppercase;
    }

    h4 {
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      color: white;
      margin: auto;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //max-height: 500px;
    //gap: 19px;

    .main {
      flex: 1;
    }

    > div:nth-of-type(2) {
      flex: 0;
    }
  }
}
