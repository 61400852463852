@import "../../../index.scss";

.container {
  position: relative;
  max-width: 270px;
  min-width: 270px;
  width: 100%;
  height: calc(100vh - 100px);
  background: #fff;
  border-right: 1px solid #e6e6e6;
  box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.1);
  color: $textMainColor;
  transition: 0.5s all ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  border-radius: $borderRadius;
  box-sizing: border-box;
  padding-top: 16px;

  .content {
    z-index: 2;
    position: relative;
    height: calc(100% - 48px);
    > div:first-of-type {
      height: 100%;
      position: absolute;
      overflow: auto;
      padding-top: 8px;
    }
  }

  > div:nth-of-type(3) {
    margin-top: auto;
  }

  .nav_group {
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;

    overflow: hidden; /* Prevent layout breaking */
    white-space: nowrap; /* Prevent wrapping */
    text-overflow: ellipsis; /* Add ellipsis when text overflows */
    transition: $defaultTransition; /* Smooth transition for opacity */

    h5 {
      padding: 0 20px;
      font-size: 16px;
      font-weight: 700;
      color: #548ad3;
      line-height: 19px;
    }
  }

  .line_break {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    height: 1px;
    background: #e6e6e6;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  .nav_item {
    border-radius: $borderRadius;
    height: 48px;
    display: flex;
    align-items: center; /* Center the contents vertically */
    margin-inline: 10px;

    &:hover:not(.active) {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px;
    }

    &.active {
      background: linear-gradient(to right, #4084de, #3bb1dd);
      color: white;

      svg {
        color: white;
        transition: $defaultTransition;

        &:hover {
          color: white;
        }
      }

      a {
        color: white;
        transition: $defaultTransition;
        font-weight: 900;
      }
      span {
        color: white;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    a {
      height: 48px;
      padding: 10px 22px;
      display: flex;
      align-items: center; /* Vertically center icon and name */
      width: 100%;
      gap: 20px;
      color: #575757;
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
      border-radius: $borderRadius;
      transition: $defaultTransition;

      &.disabled {
        pointer-events: none;
      }

      color: #808d98;
    }
    .icon {
      flex-shrink: 0; /* Prevent shrinking of the icon */
      display: flex; /* Ensure icon is centered */
      justify-content: center;
      align-items: center;
    }

    svg:hover {
      color: #808d98;
    }

    span {
      overflow: hidden; /* Prevent layout breaking */
      white-space: nowrap; /* Prevent wrapping */
      text-overflow: ellipsis; /* Add ellipsis when text overflows */
      transition: $defaultTransition; /* Smooth transition for opacity */
    }
  }

  .disk_space {
    z-index: 3;
    padding-top: 16px;
    padding-bottom: 21px;
    span {
      font-weight: 600;
    }
    .top {
      display: flex;
      align-items: center;
      gap: 17px;
      margin-left: 10px;

      .icon {
        cursor: auto;
      }
    }

    .line_break {
      margin-top: 11px;
      margin-bottom: 21px;
    }

    .bottom {
      .used_disk_space {
        border-radius: 8px;
        height: 12px;
        margin-bottom: 13px;
        background-color: #efefef;
        > div {
          border-radius: 8px;
          height: 12px;
          background: linear-gradient(
            264deg,
            #2fade5 0%,
            #379ae5 15%,
            #3f88e5 29%,
            #4873e5 45%,
            #535ae5 65%,
            #5c45e5 82%,
            #652fe6 100%
          );
        }
      }
      > span:last-of-type {
        margin-left: 31px;
      }
    }
  }
}

.container.hide {
  //transform: translateX(-100%);
  //margin-left: -250px;
  transition: 0.5s ease-in-out;
  will-change: transform;
  width: 68px;
  min-width: 68px;

  a {
    padding: 10px 12px;
    gap: 0;
  }
}

// .container.hide {
//   transform: translateX(-100%);
//   transition: transform 0.5s ease-in-out;
//   margin-left: -250px;
// }

.navItems,
.folderStructure {
  flex: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hidden {
  opacity: 0;
  //transform: translateX(-100%);
  pointer-events: none; /* Prevent interaction when hidden */
}

.navItems:not(.hidden),
.folderStructure:not(.hidden) {
  opacity: 1;
  transform: translateX(0);
}

/* Optional: Adjust the direction of the slide for folderStructure */
.folderStructure.hidden {
  transform: translateX(100%);
}
