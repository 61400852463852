.icon_container {
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
  svg:first-of-type {
    margin-left: auto;
  }
}
