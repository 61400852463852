$border-radius: 8px;
$width-scroll: 8px;
$thumb-background-color: linear-gradient(182deg, #6431E6 0%, #30AAE5 99%);
$track-background-color: #ECECEC;

* {
  box-sizing: border-box;
}

.container {
  display: grid;
  height: 100%;
  grid-template: auto / 1fr auto;
  overflow: hidden;
  position: relative;
}

.content {
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
  height: 100%;
  padding: 0;
}

.content::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  display: flex;
  justify-content: center;
  padding-right: 2px;

  .thumb {
    transition: width 0.1s ease-in-out;
  }

  &:hover .thumb {
    width: $width-scroll + 4px;
  }
}

.track-and-thumb {
  display: block;
  height: 100%;
  position: relative;
  width: $width-scroll;
}

.track {
  background: $track-background-color;
  border-radius: $border-radius;
  width: $width-scroll;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.thumb {
  position: absolute;
  background: $thumb-background-color;
  border-radius: $border-radius;
  width: $width-scroll;
  left: 50%;
  transform: translateX(-50%);
}