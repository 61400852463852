@import "../../index.scss";
.container {
  background: transparent;
  //padding: 1.8px;
  width: max-content;
  transition: 0.35s ease-out;
  border-radius: $borderRadius;
  // margin: auto;
  height: max-content;

  &.outline:hover {
    background: linear-gradient(to left, #2fade5, #379ae5, #3f88e5, #4873e5, #5918f0);
  }
  &.active {
    background: linear-gradient(to left, #2fade5, #379ae5, #3f88e5, #4873e5, #5918f0);
  }
}
