.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-width: 767px;
    padding-inline: 24px;
    @media screen and (max-width: 767px) {
      width: 100%;
      min-width: auto;
      overflow: scroll;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      gap: 16px;
      > p:first-of-type {
        font-size: 14px;
        width: 270px;
      }

      .slogan {
        font-size: 22px;
        font-weight: 600;
      }
      .button_container {
        margin-left: auto;
      }
      .half_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .error_container {
      top: -5px;
      height: 15px;
      position: relative;

      & > div {
        position: absolute;
      }
    }
    h2 {
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 5px;
      color: #548ad3;
      margin-bottom: 70px;
      font-weight: 800;
    }
    .input {
      margin-top: 15px;
    }
    span {
      color: #548ad3;
    }

    // a {
    //   font-size: 16px;
    //   font-weight: 600px;
    //   color: #548ad3;
    //   text-decoration: underline;
    // }
  }

  .text_container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: -77px;

    div {
      height: 1px;
      background-color: #e6e6e6;
    }
    > p {
      width: 100%;
      font-size: 16px;
      font-weight: 400;

      .email {
        white-space: nowrap;
        text-decoration: underline;
      }
    }
    > p:last-of-type {
      margin-bottom: 24px;
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .right {
    width: 50%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
