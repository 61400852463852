.openMore {
  display: flex;
  position: relative;
  z-index: 2;
  > svg {
    transition: all 0.25s ease-in-out;
    &:hover {
      color: white;
    }
  }

  .MuiPaper-root {
    width: 210px;
    height: 220px;
  }
}
