@import "../../index.scss";

.container {
  position: relative;

  &.dragging {
    height: 50px;
    overflow: hidden;

    * > {
      height: 50px;
    }
  }

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: self-start;

  &.three_ex_small {
    width: 78px;
    height: 78px;
  }

  &.two_ex_small {
    width: 100px;
    height: 130px;
  }

  &.ex_small {
    width: 130px;
    height: auto;
  }

  &.small {
    width: 150px;
    height: 160px;
  }

  &.medium {
    width: 160px;
    //height: 202px;
    height: auto;
  }

  .img_container {
    position: relative;
    border-radius: $borderRadius;

    svg {
      fill: #e6e6e6;
      pointer-events: bounding-box;

      &:hover {
        fill: #598dd5;
      }
    }


    .l,
    .r {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

    }

    .l {
      left: 0;
    }

    .r {
      right: 0;
    }

    &.three_ex_small {
      width: 78px;
      height: 78px;
      border: none;
      transition: $defaultTransition;

      &:hover {
        border: 1px solid $borderColor;
      }
    }

    &.two_ex_small {
      width: 100px;
      height: 100px;
    }

    &.ex_small {
      width: 130px;
      height: 130px;
    }

    &.small {
      width: 150px;
      height: 150px;
    }

    &.medium {
      width: 160px;
      height: 160px;
    }

    width: 320px;
    height: 300px;
    background-color: white;
    border: 1px solid #e6e6e6;
    padding: 8px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    //gap: 5px;

    span {
      font-size: 16px;
      font-family: "Akrobat";

      &:last-of-type {
        color: #939ea8;
        font-weight: 400;
      }

      &:first-of-type {
        color: #575757;
        font-weight: 600;
      }

      &:not(:first-of-type) {
        margin-bottom: 5px;
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hand_icon {
    margin-left: auto;
    margin-top: -25px;
    opacity: 0;
    transition: 0.25s ease-in-out;
  }

  &:hover {
    .hand_icon {
      opacity: 1;
    }
  }
}

.dragging {
  height: 30px;
}
