@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "akrobat";
  src: url("./assets/fonts/akrobat-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/akrobat-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "akrobat";
  src: url("./assets/fonts/akrobat-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/akrobat-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "akrobat";
  src: url("./assets/fonts/akrobat-semibold-webfont.woff2") format("woff2"),
    url("./assets/fonts/akrobat-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "akrobat";
  src: url("./assets/fonts/akrobat-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/akrobat-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "akrobat";
  src: url("./assets/fonts/akrobat-extrabold-webfont.woff2") format("woff2"),
    url("./assets/fonts/akrobat-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

:root {
  --mainColor: #598dd5;
  --successGreen: green;
  --defaultGradient: linear-gradient(
    to left,
    #592fe6,
    #598dd5,
    #39c1dc,
    #4084de
  );
  --errorColor: #d32f2f;
  --borderRadius: 10px;
  --borderColor: #e6e6e6;
  --defaultTransition: all 0.25s ease-in-out;
  --textMainColor: #575757;
  --textSecondaryColor: #3d3d3d;
  --gradient1: linear-gradient(to right, #30aae5, #6431e6);
  --gradient2: linear-gradient(90deg, #00ffc2 0%, #665ae3 50%, #00ffc2 100%);
  --gradient3: linear-gradient(45deg, #6fc9e0, #665ae3);
  --gradien2OClockTo7Oclock: linear-gradient(220deg, #6431e6, #30aae5);
}

$mainColor: var(--mainColor);
$successGreenColor: var(--successGreen);
$defaultGradient: var(--defaultGradient);
$errorColor: var(--errorColor);
$borderRadius: var(--borderRadius);
$borderColor: var(--borderColor);

$textMainColor: var(--textMainColor);
$textSecondaryColor: var(--textSecondaryColor);
$gradient1: var(--gradient1);
$gradient2: var(--gradient2);
$gradient3: var(--gradient3);
$defaultTransition: var(--defaultTransition);
$gradien2OClockTo7Oclock: var(--gradien2OClockTo7Oclock);
.App {
  background-color: #f8f8f8;
  overflow: hidden;
}

//*::-webkit-scrollbar {
//  display: none;
//}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  // overflow: hidden;
}

img {
  width: 100%;
  height: auto;
}

body {
  margin: 0;
  font-family: "Akrobat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

p {
  font-family: "Roboto";
}

a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.privacy-text,
.info-text {
  font-size: 14px;
  color: #939ea8;
}

//overwrite mui selec input
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-3xddzf-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 4px !important;
  // font-size: 16px;
  // font-weight: 600;
  color: #575757;
}

// .MuiTooltip-popper {
//   > div {
//     background-color: white !important;
//     color: #939ea8;
//     margin-left: 10px;
//   }
// }

.solid-line {
  border: 1px solid #e6e6e6;
}

span,
p {
  color: $textMainColor;
}

a {
  color: #598dd5;
  text-decoration: underline;
  text-underline-position: under;
  font-size: 16px;
  font-family: "Akrobat";
  line-height: 19px;
}

svg {
  transition: transform 0.2s ease-in-out; // Default transition for rotation
  cursor: pointer;
  fill: rgb(147, 158, 168);

  &:hover {
    fill: #598dd5; // Optional hover color change
    color: #598dd5; // Optional hover color change
  }

  .open {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out; // Ensure you're targeting the transform property
  }
}

.icon {
  transition: transform 0.2s ease-in-out, fill 0.2s ease-in-out; /* Smooth transition for transform and fill */
  cursor: pointer;
  fill: rgb(147, 158, 168); /* Default color */
}

.icon:hover {
  fill: #598dd5; /* Hover color change */
}

.icon.active {
  fill: #00ff00; /* Active and logged in */
}

.icon.not-logged-in {
  fill: #808d98; /* Not logged in */
}

.icon.open {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out; /* Rotation effect */
}

.modal-header-h3 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Akrobat";
  color: #598dd5;
}

.modal-text-p {
  color: $textMainColor;
  font-size: 16px;
  font-weight: 400;
  font-family: "Akrobat";
}

.link-container-modal {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 10px;
  border: 1px solid #e6e6e6;
  background-color: #f8f8f8;
  border-radius: 4px;

  > span {
    color: $textMainColor;
    font-size: 16px;
    font-weight: 700;
    font-family: "Akrobat";
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

// NOTIFICATION STYLES GLOBAL
.notification_item {
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  background-color: #ffffff;
  position: relative;
  z-index: 99999;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 20px 0;
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
  width: 300px;
  color: black;
}

@media screen and (max-width: 767px) {
  .notification_item {
    width: 250px;
  }
}

.notification_item.success {
  box-shadow: 0 0 10px rgb(127, 219, 218);
}

.notification_item.error {
  box-shadow: 0 0 10px rgba(255, 4, 4, 1);
}

.notification_item.warning {
  box-shadow: 0 0 10px rgb(190, 178, 7);
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.notification_item.exit {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}

.notification_item p {
  margin: 0;
  padding: 10px;
}

.notification_item .bar {
  height: 10px;
}

.notification_item.success .bar {
  background-color: rgb(9, 174, 66);
}

.notification_item.error .bar {
  background-color: rgb(190, 7, 7);
}

.notification_item.warning .bar {
  background-color: rgb(190, 178, 7);
}

.notification_wrapper {
  position: fixed;
  top: 80px;
  right: 10px;
  width: 300px;
  z-index: 99;
}

.MuiBox-root {
  border: none !important;
  outline: none !important;
  bottom: 10px !important;
  border-radius: 8px !important;
}

.css-1k9ek97 {
  max-width: 1280px;
  width: 100%;
  margin: auto;
  @media screen and (max-width: 1280px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.bin-icon-mui {
  &:hover {
    color: $errorColor;
  }
}

.container-folder-tree-structure {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top: none;
  margin-top: -18px;
}

.css-fc66vf-MuiRichTreeView-root {
  margin-top: 10px !important;
}

.container-folder-tree-structure-nav {
  margin: 3px;

  h4 {
    color: $mainColor;
    margin: 15px;
  }
}

.MuiInputBase-input.Mui-disabled {
  color: var(--mainColor); /* Use your main color variable */
}

.Mui-disabled {
  -webkit-text-fill-color: $mainColor; /* Ensure red text color in WebKit-based browsers */
}

// .MuiRichTreeView-root {
//   border-radius: $borderRadius;
// }
.line {
  height: 1px;
  width: 100%;
  background: $borderColor;
}

//.container-scrollbar {
//  overflow: auto;
//}
//
//.container-scrollbar::-webkit-scrollbar {
//  width: 8px !important;
//}
//
//.container-scrollbar::-webkit-scrollbar-track {
//  background-color: #ECECEC !important;
//  border-radius: 8px;
//  width: 8px;
//}
//
//.container-scrollbar::-webkit-scrollbar-thumb {
//  background: linear-gradient(182deg, #6431E6 0%, #30AAE5 99%);
//  border-radius: 8px;
//  opacity: 1;
//
//}
