@import "../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  min-width: 12.5rem;
  width: 201px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.35);
  border-radius: $borderRadius;
  overflow: visible;
  padding: 10px 0;
  z-index: 3;
  position: relative;

  .option_item {
    top: 20px;
    display: flex;
    align-items: center;
    padding-inline: 20px 10px;
    height: 32px;
    cursor: pointer;

    a {
      color: rgb(87, 87, 87);
      font-size: 16px;
      font-weight: 600;
      padding-left: 10px;
      padding-top: 3px;
      text-decoration: none;
      transition: all 0.25s ease-in-out;
    }

    &:hover {
      background-color: #e2eff4;
    }
  }

  .solid_line {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
