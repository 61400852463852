@import "../../index.scss";
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  min-width: 320px;
  max-width: 380px;
  height: clamp(500px, 60vh, 700px);
  padding: 26px 50px 50px 50px;
  background: linear-gradient(#ffffff, #eef0fe);
  box-sizing: border-box;
  border-radius: 32px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px;
    cursor: pointer;
  }

  &.big {
    padding-top: 62px;
    height: clamp(570px, 68vh, 770px);
  }

  .container_label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    max-width: 250px;
    width: 250px;
    height: 40px;
    padding: 9px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: linear-gradient(90deg, #6eb7e1, #e62f47);
    font-family: Akrobat;
    font-size: 18px;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @media screen and (max-width: 480px) {
      width: 180px;
    }
  }

  &.active {
    position: relative;
  }

  &.active::before {
    content: "";
    position: absolute;
    border-radius: 32px;
    inset: 0;
    border: 8px solid transparent;
    background: linear-gradient(30deg, #665ae3, #6fc9e0) border-box;
    mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
    mask-composite: exclude;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-transform: capitalize;

    span,
    p {
      color: $textSecondaryColor;
    }

    span:first-of-type {
      font-size: 24px;
      font-weight: 900;
      letter-spacing: 0.5px;
      line-height: 29px;
      color: #548ad3;
    }
    span:last-of-type {
      font-weight: 900;
      font-size: 36px;
      line-height: 43px;
      padding-bottom: 19px;
    }
    > p {
      font-size: 16px;
      font-family: "Roboto";
      font-weight: 400;
      line-height: 21px;
    }
  }

  .price {
    display: flex;
    flex-direction: column;

    div,
    & > span {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .value {
      align-items: flex-end;
      gap: 4px;
      padding-bottom: 5px;

      span {
        color: $textSecondaryColor;
      }

      span:first-of-type {
        font-size: 36px;
        font-weight: 900;
      }

      span:last-of-type {
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 3px;
      }
    }

    > span:last-of-type {
      font-family: "Roboto";
      color: #acacac;
      font-size: 15px;
      line-height: 21px;
    }
  }
  .perk_container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    transition: all 0.5s ease-in-out;
    z-index: 10;
    padding-left: 4px;

    &::-webkit-scrollbar {
      width: 4px; /* Thin scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2); /* Transparent-ish thumb */
      border-radius: 4px; /* Rounded edges */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.4); /* Slightly more visible on hover */
    }
    span {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      text-align: center;
      color: $textSecondaryColor;
      width: 100%;
      padding: 10px 8px;
    }
  }
  > div {
    display: flex;
    align-items: center;
  }
  .show_more {
    margin: 0 auto;

    > svg {
      transition: all 0.25s ease-out;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
}
