@import "../../../index.scss";

.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    min-width: 767px;
    padding-inline: 24px;
    @media screen and (max-width: 767px) {
      min-width: auto;
      width: 100%;
      overflow: scroll;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      gap: 10px;

      .headers {
        display: flex;
        flex-direction: column;
        gap: 12px;

        h2 {
          font-size: 24px;
          font-weight: 900;
          color: #548ad3;
        }

        h3 {
          padding: 0;
          text-transform: capitalize;
          font-weight: 900;
          font-size: 36px;
          color: $textSecondaryColor;
          margin-bottom: 32px;
        }
      }

      & > span:first-of-type {
        color: #548ad3;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 13px;
      }

      .message_container {
        height: 10px;
        position: relative;

        & > div {
          max-width: 260px;
          position: absolute;
        }
      }

      .buttons_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 26px;

        @media screen and (max-width: 767px) {
          padding-top: 20px;
          padding-bottom: 13px;
        }
      }
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .right {
    width: 50%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
