.container {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  //gap: 15px;
  overflow: hidden;
  flex-grow: 1;
  //overflow-y: scroll;
  min-height: 0;
  //scrollbar-width: auto;
  //-ms-overflow-style: auto;
  max-height: calc(100vh - 415px);
  //scrollbar-color: #4C6DD6 #f0f0f0;
  margin-bottom: 10px;
  padding-left: 3px;

  .folder {
    &_node_container {
      //overflow: hidden;
      overflow: initial;
      z-index: 0;
      position: relative;
    }
  }

  .folders {
    display: flex;
    gap: 25px;
  }

  .product_list {
    display: flex;
    flex-direction: column;
    gap: 47px;
    flex-wrap: wrap;
    padding-top: 16px;

    .map_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 16px;
    }
  }
}