.container {
  display: flex;
  height: 60px;
  background-color: #eef0fe;
  border-radius: 36px;

  .yearly {
    margin-left: -8px;
  }

  .monthly,
  .yearly {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 148px;
    border-radius: 36px;
    font-family: "Roboto";
    line-height: 18px;
    padding-top: 2px;

    &.active {
      position: relative;
      background: white;
    }

    &:not(.active) {
      cursor: pointer;
    }

    &.active::before {
      content: "";
      position: absolute;
      border-radius: 32px;
      inset: 0;
      border: 5px solid transparent;
      background: linear-gradient(30deg, #665ae3, #6fc9e0) border-box;
      mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
      mask-composite: exclude;
    }

    > span {
      font-weight: 600;
      color: black;
    }

    > div {
      font-size: 14px;
      background: linear-gradient(90deg, #665ae3, #dd7c82 50%);
      background-size: 200%; /* Set up for animation */
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent; /* Make text transparent to show background */
      font-weight: 900;

      span:first-of-type {
        font-size: 18px;
      }
      span:last-of-type {
        font-size: 14px;
      }
    }
  }
}
