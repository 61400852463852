.container {
  display: flex;
  padding: 30px;
  .one {
    width: 30%;
  }
  .two {
    width: 70%;
  }
  .map_container {
    display: flex;
    flex-wrap: wrap;
  }
}
