.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    min-width: 1080px;
    height: 100%;
    @media screen and (max-width: 1080px) {
      width: 100%;
      min-width: auto;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 80%;
      max-width: 1200px;
      gap: 30px;
      padding-top: 24px;
      padding-bottom: 24px;

      @media screen and (max-width: 1080px) {
        padding-inline: 5%;
        width: 100%;
        min-width: auto;
      }
      @media screen and (max-width: 600px) {
        justify-content: space-between;
      }
      > span {
        margin-bottom: 20px;
      }

      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          margin: 0;
          color: black;
          font-family: "Roboto";
          font-size: 16px;
          letter-spacing: 0;
        }
      }

      .inner_container {
        width: 100%;
        height: clamp(600px, 72vh, 790px);
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        gap: 50px;
        justify-content: center;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }

      .inner_container_mobile {
        display: none;

        @media screen and (max-width: 900px) {
          width: 100%;
          height: clamp(600px, 74vh, 790px);
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 670px;
          margin: 0 auto;
          padding-inline: 12px;
        }
        @media screen and (max-width: 900px) {
          margin-bottom: 50px;
        }

        .subscription_swiper {
          padding: 10px 0;
          display: flex;
          justify-content: center;
          overflow: visible;
          min-width: 450px;
          width: 100%;

          .swiper_slide {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 30px;
          }

          > div:last-of-type {
            bottom: -30px;
          }
          @media screen and (max-width: 600px) {
            > div:nth-of-type(2) {
              top: calc(100% + 24px);
              left: calc(50% - 88px);
              z-index: 11;
            }
            > div:nth-of-type(3) {
              top: calc(100% + 24px);
              right: calc(50% - 88px);
              z-index: 11;
            }
          }
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          text-transform: uppercase;
        }
      }
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 5px;
      color: #548ad3;
      margin-bottom: 55px;
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
  .right {
    width: 30%;
    overflow: hidden;
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
}
