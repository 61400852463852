.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-width: 767px;
    padding-inline: 24px;
    @media screen and (max-width: 767px) {
      width: 100%;
      min-width: auto;
      overflow: scroll;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      gap: 10px;
      > p:first-of-type {
        font-size: 22px;
        margin-top: 40px;
      }

      .half_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        padding-top: 12px;
        padding-bottom: 40px;
        gap: 10px;

        button {
          width: 160px;
        }
      }

      .error_container {
        height: 10px;
        position: relative;

        & > div {
          position: absolute;
        }
      }

      & > span {
        color: #548ad3;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 13px;
      }

      .links_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        gap: 20px;

        p:first-child {
          font-weight: 800;
        }

        p:last-of-type {
          font-weight: 600;
        }

        .buttons_container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
      }
    }
    h2 {
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 5px;
      color: #548ad3;
      margin-bottom: 68px;
      font-weight: 800;
    }
    input {
      line-height: 16px;
    }

    p {
      font-family: "Akrobat";
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .right {
    width: 50%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
